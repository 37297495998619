import { GatsbySeo } from 'gatsby-plugin-next-seo'
import CartaoPresente from 'src/components/servicos/ServicesPage/CartaoPresente'

function Page() {
  return (
    <>
      <GatsbySeo title="Cartão Presente | Decathlon" />
      <CartaoPresente />
    </>
  )
}

export default Page
